import CarouselHeader from "components/CarouselHeader"
import ShopHeader from "components/ShopHeader"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button, Icon } from "nzk-react-components"
import React, { useMemo } from "react"
import styled from "styled-components"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import getGatsbyImage from "utils/getGatsbyImage"
import { NON_DECIMAL_CURRENCIES } from "../constants"
import useHubspotTracking from "../hooks/useHubspotTracking"
import { formatProductForCountry } from "../hooks/useStrapiShopProduct"
import Layout from "../layouts/ShopLayout"
import { useShopLocaleState } from "../providers/ShopLocaleProvider"

interface IProps {
  data: {
    site: {
      siteMetadata: { siteUrl: string }
    }
    strapiShopCategories: Strapi.ShopCategory
    allStrapiCountries: {
      edges: {
        node: Strapi.Country
      }[]
    }
  }
}

const Wrapper = styled.div`
  padding-top: 0px !important;
  > :first-child > :first-child {
    padding-top: 50px;
  }
  margin-bottom: 60px;
`

const Products = styled.div`
  display: grid;
  grid-gap: 20px;
  padding: 20px 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

const Product = styled.div`
  .product--title {
    color: #662d91;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    line-height: 1.2;
    height: 52px;
  }
  .product--price {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .product--actions {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
  }
`

const Thumbnail = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 66%;
  background-color: rgb(235, 235, 235);
  border-radius: 14px;
  overflow: hidden;
  > :first-child {
    position: absolute;
    top: 5%;
    left: 5%;
    height: 90%;
    width: 90%;
    padding: 0 20px;
  }
`

const EditQuantity = styled.div`
  display: flex;
  color: #662d91;
  align-items: center;
  margin-right: auto;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const ShopCategoryTemplate = (props: IProps) => {
  const { data } = props
  const category = data.strapiShopCategories
  const { products } = category
  const { locale } = useShopLocaleState()
  const { trackCustomEvent } = useHubspotTracking()
  const { addItem, cartDetails, incrementItem, decrementItem } =
    useShoppingCart()

  const countryEdges = data.allStrapiCountries.edges.filter(
    edge => edge.node.locale === locale
  )
  const country = countryEdges[0].node

  const availableProducts = (products || [])
    .map(p => formatProductForCountry(p, country))
    .filter(s => s.skus.length > 0 && s.available)

  const onAddItem = (product: Strapi.ShopProduct) => {
    const sku = product.skus[0]
    if (Object.keys(cartDetails).length === 0) {
      trackCustomEvent("pe19679358_created_basket", {})
    }
    addItem(
      {
        id: sku.sku,
        sku: sku.sku,
        price: sku.pricings[0].amount,
        currency: sku.pricings[0].currency.toUpperCase(),
        name: product.name,
        description: sku.shortDescription,
        image: sku.thumbnail?.url || product.thumbnail?.url,
        metadata: {
          warehouse: sku.warehouse,
          digital: product.digital,
          shopShippingRate: sku.pricings[0].shopShippingRate,
          countryCodes: product.digital ? [] : sku.pricings[0].countries.map(c => c.code),
          shippingRate: product.digital ? undefined : sku.pricings[0].shippingRate,
        },
      },
      { count: 1 }
    )
  }

  const slides =
    category.CarouselHero?.CarouselHeroElements.map(element => ({
      id: element.id,
      backgroundImage: element.backgroundImage,
      image: element.image,
      title: element.title,
      button: element.button && {
        label: element.button.value,
        target: element.button.targetUrl,
        external: element.button.targetUrl[0] !== "/",
      },
      BulletItems: element.BulletItems,
      richContent: element.richContent,
    })) || []

  const carousel = useMemo(() => {
    if (slides.length > 0) return <CarouselHeader slides={slides} />
    return null
  }, [])

  return (
    <Wrapper>
      {carousel}
      <ShopHeader />
      <Products>
        {availableProducts
          .sort(
            (a, b) =>
              (a.position || parseInt(a.id, 10)) -
              (b.position || parseInt(b.id, 10))
          )
          .map(p => {
            const sku = p.skus[0]
            const amount =
              NON_DECIMAL_CURRENCIES.indexOf(
                sku.pricings[0].currency.toUpperCase()
              ) >= 0
                ? sku.pricings[0].amount
                : sku.pricings[0].amount / 100
            const price = new Intl.NumberFormat("en-us", {
              style: "currency",
              currency: sku.pricings[0].currency,
            }).format(amount)
            return (
              <Product key={p.id}>
                <Link to={`/shop/products/${p.slug}`}>
                  {p.thumbnail && (
                    <Thumbnail>
                      <GatsbyImage
                        image={getGatsbyImage(p.thumbnail.localFile)}
                        alt={p.thumbnail.alternativeText || ""}
                        objectFit="contain"
                      />
                    </Thumbnail>
                  )}
                  <h3 className="product--title">{p.name}</h3>
                  <div className="product--price">{price}</div>
                </Link>
                <div className="product--actions">
                  {p.addToBasketButton ? (
                    <Button
                      size="regular"
                      theme="primary"
                      onClick={() => navigate(p.addToBasketButton!.targetUrl)}
                    >
                      {p.addToBasketButton!.value}
                    </Button>
                  ) : (
                    <>
                      {cartDetails[sku.sku]?.quantity >= 1 ? (
                        <EditQuantity>
                          <div>Quantity: {cartDetails[sku.sku]?.quantity}</div>
                          <Button
                            size="x-small"
                            theme="purple"
                            round
                            onClick={() => decrementItem(sku.sku, { count: 1 })}
                          >
                            <Icon name="minus" />
                          </Button>
                          <Button
                            size="x-small"
                            theme="purple"
                            round
                            onClick={() => incrementItem(sku.sku, { count: 1 })}
                          >
                            <Icon name="plus" />
                          </Button>
                        </EditQuantity>
                      ) : (
                        <Button
                          size="regular"
                          theme="primary"
                          onClick={() => onAddItem(p)}
                        >
                          Add to basket
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Product>
            )
          })}
      </Products>
    </Wrapper>
  )
}

export const query = graphql`
  query ($id: Int) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStrapiCountries {
      edges {
        node {
          locale
          name
          currencyCode
          conversionRateToUSD
        }
      }
    }
    strapiShopCategories(strapiId: { eq: $id }) {
      id
      title
      slug
      products {
        id
        position
        slug
        available
        name
        digital
        thumbnail {
          url
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 350, placeholder: BLURRED, quality: 80)
            }
          }
        }
        addToBasketButton {
          value
          theme
          targetUrl
        }
        skus {
          id
          sku
          shortDescription
          thumbnail {
            url
            alternativeText
          }
          warehouse
          pricings {
            countries {
              locale
              code
            }
            shippingRate
            shopShippingRate {
              ...ShippingRate
            }
            currency
            amount
            discountedPercent
            originalAmount
          }
        }
      }
      CarouselHero {
        ...CarouselHero
      }
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default (props: IProps) => {
  const { data } = props
  const category = data.strapiShopCategories

  const getDefaultSEO = (category: Strapi.ShopCategory) => ({
    description:
      category.SEO?.description || `${category.description?.slice(0, 140)}...`,
    image:
      category.SEO?.image?.url ||
      category.CarouselHero?.CarouselHeroElements[0]?.backgroundImage.url,
    title: category.SEO?.title || category.title,
    noIndex: category.SEO?.noIndex || false,
    url:
      category.SEO?.url ||
      `${data.site.siteMetadata.siteUrl}/shop/categories/${category.slug}`,
    canonical: category.SEO?.canonical || null,
  })

  return (
    <Layout seoProps={getDefaultSEO(category)}>
      <ShopCategoryTemplate {...props} />
    </Layout>
  )
}
