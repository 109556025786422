import { animated } from "react-spring"
import styled from "styled-components"

export const Wrapper = styled.div`
  min-height: 400px;
  position: relative;
  .slide--title {
    font-size: 34px;
    color: #fff;
    margin-bottom: 23px;
    pointer-events: none;
    user-select: none;
    line-height: 1.3;
  }
  overflow: hidden;
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #afafaf;
  z-index: -1;
  > :first-child {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`

export const Slide = styled(animated.div)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
  @media (max-width: 900px) {
    padding-top: 50px;
  }
`

export const Container = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  @media (max-width: 900px) {
    min-height: 500px;
  }
`

export const Content = styled.div`
  width: 100%;
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  height: 100%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  a {
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    user-drag: none;
    -webkit-user-drag: none;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
  }
`

export const Picture = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 510px;
  pointer-events: none;
  max-height: 350px;
  > :first-child {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 900px) {
    height: 250px;
  }
`

export const Pusher = styled.div`
  opacity: 0;
  visibility: hidden;
  z-index: -1;
`

export const BulletItems = styled.div`
  color: #fff;
  pointer-events: none;
  margin-bottom: 23px;
  > * {
    margin-bottom: 8px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export const BulletItem = styled.div`
  > :first-child {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
`

export const Dots = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
  > * {
    margin-right: 15px;
  }
  > :last-child {
    margin-right: 0;
  }
`

export const Dot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  opacity: ${(props: { selected: boolean }) => (props.selected ? 1 : 0.5)};
  cursor: pointer;
`
