import { useState } from "react"
import debounce from "utils/debounce"
import useEventListener from "./useEventListener"

const HAS_WINDOW = typeof window !== "undefined"
const useWindowSize = () => {
  const [dimensions, setDimensions] = useState({
    width: HAS_WINDOW ? window.innerWidth : 0,
    height: HAS_WINDOW ? window.innerHeight : 0,
  })

  useEventListener(
    "resize",
    debounce(() => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight })
    }, 250),
    []
  )

  return dimensions
}

export default useWindowSize
